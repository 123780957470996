class SingleVideoTranscript {
    constructor() {
        this.btnShowTranscript = document.querySelectorAll("[data-show-transcript-video]");
        this.init();
    }

    init() {
        const $this = this;

        if ($this.btnShowTranscript) {
            $this.btnShowTranscript.forEach(node => {
                node.addEventListener("click", e => {
                    const transcript = document.querySelector("." + node.getAttribute("data-transcript-target"));
                    e.preventDefault();
                    const playerWrap = node.closest(".video-player-wrap");
                    node.classList.toggle("js-expanded");
                    playerWrap.classList.toggle("js-expanded");

                    transcript.toggleAttribute("aria-hidden");
                    transcript.toggleAttribute("inert");
                });
            });
        }
    }
}

export default SingleVideoTranscript;
